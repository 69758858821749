import type { RouteLocationNormalizedLoaded } from '#vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import lodashToString from 'lodash-es/toString';

import type { BusDossierEntity } from '~/apiClient';
import { getApiGetBusDossierQueryKey } from '~/apiClient';
import type { BusDossierPayload, MessagePayload, MessageType, SyncStartPayload } from '~/types/messages.type';

const isBusDossierOfflineRoute = (route: RouteLocationNormalizedLoaded, busDossier: BusDossierEntity): boolean => {
    const id = lodashToString(route.params.id);
    const routeName = lodashToString(route.name);

    return routeName.startsWith('bus-dossiers-id') && isOfflineBusDossierId(id) && id.includes(busDossier.vin);
};

export default defineNuxtPlugin({
    name: 'offline',
    dependsOn: ['vueQuery'],
    setup() {
        const queryClient = useQueryClient();
        const route = useRoute();
        const { notifyInfo } = useNotification();

        // HANDLERS
        const busDossierCreatedHandler = (payload: BusDossierPayload): void => {
            queryClient.invalidateQueries({ queryKey: ['busDossiers'] });
            isBusDossierOfflineRoute(route, payload.busDossier) &&
                navigateTo({
                    name: route.name,
                    params: { id: payload.busDossier.id },
                });
        };

        const busDossierUpdatedHandler = (): void => {
            queryClient.invalidateQueries({ queryKey: ['busDossiers'] });
        };

        const busDossierImageUploadedHandler = (payload: BusDossierPayload): void => {
            const oldBd: BusDossierEntity | undefined = queryClient.getQueryData(getApiGetBusDossierQueryKey(payload.busDossier.id));

            if (oldBd) {
                oldBd.media.map(m => m.meta.map(m => m.url.startsWith('blob:') && URL.revokeObjectURL(m.url)));
            }

            queryClient.invalidateQueries({ queryKey: ['busDossiers'] });
        };

        const busDossierVideoUploadedHandler = (): void => {
            queryClient.invalidateQueries({ queryKey: ['busDossiers'] });
        };

        const syncStartHandler = (payload: SyncStartPayload): void => {
            notifyInfo({
                title: 'Starting offline data sync',
                description: `The app is syncing data for ${payload.name}; this might take a while, and the data shown may be outdated.`,
            });
        };

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (event: MessageEvent<{ type: MessageType; payload: MessagePayload }>) => {
                const { type, payload } = event.data;

                switch (type) {
                    case 'BusDossierCreated':
                        busDossierCreatedHandler(payload as BusDossierPayload);
                        break;
                    case 'BusDossierUpdated':
                        busDossierUpdatedHandler();
                        break;
                    case 'BusDossierImageUploaded':
                        busDossierImageUploadedHandler(payload as BusDossierPayload);
                        break;
                    case 'BusDossierVideoUploaded':
                        busDossierVideoUploadedHandler();
                        break;
                    case 'SyncStart':
                        syncStartHandler(payload as SyncStartPayload);
                        break;
                }
            });
        }
    },
});
