import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ApiGetBusFactoryAttributesListParams,
  BackendErrorEntity,
  BusFactoryAttributesEntity,
  CreateBusFactoryAttributesBodyDto,
  GetBusFactoryAttributesListResponseDto,
  UpdateBusFactoryAttributesBodyDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiCreateBusFactoryAttributes = (
    createBusFactoryAttributesBodyDto: MaybeRef<CreateBusFactoryAttributesBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createBusFactoryAttributesBodyDto = unref(createBusFactoryAttributesBodyDto);
      
      return fetchApiInstance<BusFactoryAttributesEntity>(
      {url: `/bus-factory-attributes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBusFactoryAttributesBodyDto
    },
      options);
    }
  


export const getApiCreateBusFactoryAttributesMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, TError,{data: BodyType<CreateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, TError,{data: BodyType<CreateBusFactoryAttributesBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, {data: BodyType<CreateBusFactoryAttributesBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateBusFactoryAttributes(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateBusFactoryAttributesMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>>
    export type ApiCreateBusFactoryAttributesMutationBody = BodyType<CreateBusFactoryAttributesBodyDto>
    export type ApiCreateBusFactoryAttributesMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateBusFactoryAttributes = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, TError,{data: BodyType<CreateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>,
        TError,
        {data: BodyType<CreateBusFactoryAttributesBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateBusFactoryAttributesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetBusFactoryAttributesList = (
    params?: MaybeRef<ApiGetBusFactoryAttributesListParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<GetBusFactoryAttributesListResponseDto>(
      {url: `/bus-factory-attributes`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetBusFactoryAttributesListQueryKey = (params?: MaybeRef<ApiGetBusFactoryAttributesListParams>,) => {
    return ['bus-factory-attributes', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetBusFactoryAttributesListQueryOptions = <TData = Awaited<ReturnType<typeof apiGetBusFactoryAttributesList>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiGetBusFactoryAttributesListParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributesList>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetBusFactoryAttributesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetBusFactoryAttributesList>>> = () => apiGetBusFactoryAttributesList(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributesList>>, TError, TData> 
}

export type ApiGetBusFactoryAttributesListQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetBusFactoryAttributesList>>>
export type ApiGetBusFactoryAttributesListQueryError = ErrorType<BackendErrorEntity>



export function useApiGetBusFactoryAttributesList<TData = Awaited<ReturnType<typeof apiGetBusFactoryAttributesList>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiGetBusFactoryAttributesListParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributesList>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetBusFactoryAttributesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateBusFactoryAttributes = (
    id: MaybeRef<string | undefined | null>,
    updateBusFactoryAttributesBodyDto: MaybeRef<UpdateBusFactoryAttributesBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
updateBusFactoryAttributesBodyDto = unref(updateBusFactoryAttributesBodyDto);
      
      return fetchApiInstance<BusFactoryAttributesEntity>(
      {url: `/bus-factory-attributes/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateBusFactoryAttributesBodyDto
    },
      options);
    }
  


export const getApiUpdateBusFactoryAttributesMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, TError,{id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, TError,{id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, {id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  apiUpdateBusFactoryAttributes(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateBusFactoryAttributesMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>>
    export type ApiUpdateBusFactoryAttributesMutationBody = BodyType<UpdateBusFactoryAttributesBodyDto>
    export type ApiUpdateBusFactoryAttributesMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateBusFactoryAttributes = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, TError,{id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>,
        TError,
        {id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateBusFactoryAttributesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetBusFactoryAttributes = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<BusFactoryAttributesEntity>(
      {url: `/bus-factory-attributes/${id}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetBusFactoryAttributesQueryKey = (id: MaybeRef<string | undefined | null>,) => {
    return ['bus-factory-attributes',id] as const;
    }

    
export const getApiGetBusFactoryAttributesQueryOptions = <TData = Awaited<ReturnType<typeof apiGetBusFactoryAttributes>>, TError = ErrorType<BackendErrorEntity>>(id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributes>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetBusFactoryAttributesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetBusFactoryAttributes>>> = () => apiGetBusFactoryAttributes(id, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributes>>, TError, TData> 
}

export type ApiGetBusFactoryAttributesQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetBusFactoryAttributes>>>
export type ApiGetBusFactoryAttributesQueryError = ErrorType<BackendErrorEntity>



export function useApiGetBusFactoryAttributes<TData = Awaited<ReturnType<typeof apiGetBusFactoryAttributes>>, TError = ErrorType<BackendErrorEntity>>(
 id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributes>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetBusFactoryAttributesQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



