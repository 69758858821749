import { THEME_PRIMARY_COLOR_LOCAL_STORAGE_KEY, THEME_PRIMARY_COLORS } from '~/constants/theme';

export default defineNuxtPlugin({
    name: 'theme',
    setup() {
        const appConfig = useAppConfig();

        const primaryColor = localStorage.getItem(THEME_PRIMARY_COLOR_LOCAL_STORAGE_KEY);

        if (primaryColor && THEME_PRIMARY_COLORS.includes(primaryColor)) {
            appConfig.ui.primary = primaryColor;
        }
    },
});
