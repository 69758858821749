export const THEME_PRIMARY_COLORS = [
    'red',
    'orange',
    'amber',
    'yellow',
    'lime',
    'green',
    'emerald',
    'teal',
    'cyan',
    'sky',
    'blue',
    'indigo',
    'violet',
    'purple',
    'fuchsia',
    'pink',
    'rose',
];

export const THEME_DEFAULT_PRIMARY_COLOR = 'violet';

export const THEME_PRIMARY_COLOR_LOCAL_STORAGE_KEY = 'primary-color';
