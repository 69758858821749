import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ApiGetBusDossiersParams,
  BackendErrorEntity,
  BusDossierEntity,
  CreateBusDossierBodyDto,
  DeleteBusDossierDamageImageBodyDto,
  DeleteBusDossierImageBodyDto,
  DeleteBusDossierVideoBodyDto,
  GetBusDossiersResponseDto,
  PublishBusDossierResponseDto,
  UpdateBusDossierBodyDto,
  UpdateOnSiteDataCollectedBodyDto,
  UploadBusDossierBaseImageBodyDto,
  UploadBusDossierCocDocumentBodyDto,
  UploadBusDossierDamageImageBodyDto,
  UploadBusDossierMaintenanceCardBodyDto,
  UploadBusDossierRegistrationDocumentBodyDto,
  UploadBusDossierVideoBodyDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiGetBusDossiers = (
    params?: MaybeRef<ApiGetBusDossiersParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<GetBusDossiersResponseDto>(
      {url: `/busDossiers`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetBusDossiersQueryKey = (params?: MaybeRef<ApiGetBusDossiersParams>,) => {
    return ['busDossiers', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetBusDossiersQueryOptions = <TData = Awaited<ReturnType<typeof apiGetBusDossiers>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiGetBusDossiersParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusDossiers>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetBusDossiersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetBusDossiers>>> = () => apiGetBusDossiers(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetBusDossiers>>, TError, TData> 
}

export type ApiGetBusDossiersQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetBusDossiers>>>
export type ApiGetBusDossiersQueryError = ErrorType<BackendErrorEntity>



export function useApiGetBusDossiers<TData = Awaited<ReturnType<typeof apiGetBusDossiers>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiGetBusDossiersParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusDossiers>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetBusDossiersQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiCreateBusDossier = (
    createBusDossierBodyDto: MaybeRef<CreateBusDossierBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createBusDossierBodyDto = unref(createBusDossierBodyDto);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBusDossierBodyDto
    },
      options);
    }
  


export const getApiCreateBusDossierMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusDossier>>, TError,{data: BodyType<CreateBusDossierBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusDossier>>, TError,{data: BodyType<CreateBusDossierBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateBusDossier>>, {data: BodyType<CreateBusDossierBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateBusDossier(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateBusDossierMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateBusDossier>>>
    export type ApiCreateBusDossierMutationBody = BodyType<CreateBusDossierBodyDto>
    export type ApiCreateBusDossierMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateBusDossier = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusDossier>>, TError,{data: BodyType<CreateBusDossierBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateBusDossier>>,
        TError,
        {data: BodyType<CreateBusDossierBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateBusDossierMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetBusDossier = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetBusDossierQueryKey = (busDossierId: MaybeRef<string | undefined | null>,) => {
    return ['busDossiers',busDossierId] as const;
    }

    
export const getApiGetBusDossierQueryOptions = <TData = Awaited<ReturnType<typeof apiGetBusDossier>>, TError = ErrorType<BackendErrorEntity>>(busDossierId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusDossier>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetBusDossierQueryKey(busDossierId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetBusDossier>>> = () => apiGetBusDossier(busDossierId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(busDossierId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetBusDossier>>, TError, TData> 
}

export type ApiGetBusDossierQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetBusDossier>>>
export type ApiGetBusDossierQueryError = ErrorType<BackendErrorEntity>



export function useApiGetBusDossier<TData = Awaited<ReturnType<typeof apiGetBusDossier>>, TError = ErrorType<BackendErrorEntity>>(
 busDossierId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusDossier>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetBusDossierQueryOptions(busDossierId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateBusDossier = (
    busDossierId: MaybeRef<string | undefined | null>,
    updateBusDossierBodyDto: MaybeRef<UpdateBusDossierBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
updateBusDossierBodyDto = unref(updateBusDossierBodyDto);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateBusDossierBodyDto
    },
      options);
    }
  


export const getApiUpdateBusDossierMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusDossier>>, TError,{busDossierId: string;data: BodyType<UpdateBusDossierBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusDossier>>, TError,{busDossierId: string;data: BodyType<UpdateBusDossierBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateBusDossier>>, {busDossierId: string;data: BodyType<UpdateBusDossierBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUpdateBusDossier(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateBusDossierMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateBusDossier>>>
    export type ApiUpdateBusDossierMutationBody = BodyType<UpdateBusDossierBodyDto>
    export type ApiUpdateBusDossierMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateBusDossier = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusDossier>>, TError,{busDossierId: string;data: BodyType<UpdateBusDossierBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateBusDossier>>,
        TError,
        {busDossierId: string;data: BodyType<UpdateBusDossierBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateBusDossierMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBusDossier = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteBusDossierMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossier>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossier>>, TError,{busDossierId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBusDossier>>, {busDossierId: string}> = (props) => {
          const {busDossierId} = props ?? {};

          return  apiDeleteBusDossier(busDossierId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBusDossierMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBusDossier>>>
    
    export type ApiDeleteBusDossierMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBusDossier = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossier>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBusDossier>>,
        TError,
        {busDossierId: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteBusDossierMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiPublishBusDossier = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<PublishBusDossierResponseDto>(
      {url: `/busDossiers/${busDossierId}/publish`, method: 'POST'
    },
      options);
    }
  


export const getApiPublishBusDossierMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiPublishBusDossier>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiPublishBusDossier>>, TError,{busDossierId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiPublishBusDossier>>, {busDossierId: string}> = (props) => {
          const {busDossierId} = props ?? {};

          return  apiPublishBusDossier(busDossierId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiPublishBusDossierMutationResult = NonNullable<Awaited<ReturnType<typeof apiPublishBusDossier>>>
    
    export type ApiPublishBusDossierMutationError = ErrorType<BackendErrorEntity>

    export const useApiPublishBusDossier = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiPublishBusDossier>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiPublishBusDossier>>,
        TError,
        {busDossierId: string},
        TContext
      > => {

      const mutationOptions = getApiPublishBusDossierMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUploadBusDossierBaseImage = (
    busDossierId: MaybeRef<string | undefined | null>,
    uploadBusDossierBaseImageBodyDto: MaybeRef<UploadBusDossierBaseImageBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
uploadBusDossierBaseImageBodyDto = unref(uploadBusDossierBaseImageBodyDto);
      const formData = new FormData();
formData.append('busDossierImage', uploadBusDossierBaseImageBodyDto.busDossierImage)

      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/baseImages`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getApiUploadBusDossierBaseImageMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierBaseImage>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierBaseImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierBaseImage>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierBaseImageBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUploadBusDossierBaseImage>>, {busDossierId: string;data: BodyType<UploadBusDossierBaseImageBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUploadBusDossierBaseImage(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUploadBusDossierBaseImageMutationResult = NonNullable<Awaited<ReturnType<typeof apiUploadBusDossierBaseImage>>>
    export type ApiUploadBusDossierBaseImageMutationBody = BodyType<UploadBusDossierBaseImageBodyDto>
    export type ApiUploadBusDossierBaseImageMutationError = ErrorType<BackendErrorEntity>

    export const useApiUploadBusDossierBaseImage = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierBaseImage>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierBaseImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUploadBusDossierBaseImage>>,
        TError,
        {busDossierId: string;data: BodyType<UploadBusDossierBaseImageBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUploadBusDossierBaseImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBusDossierImage = (
    busDossierId: MaybeRef<string | undefined | null>,
    deleteBusDossierImageBodyDto: MaybeRef<DeleteBusDossierImageBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
deleteBusDossierImageBodyDto = unref(deleteBusDossierImageBodyDto);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/images`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteBusDossierImageBodyDto
    },
      options);
    }
  


export const getApiDeleteBusDossierImageMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierImage>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierImage>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierImageBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBusDossierImage>>, {busDossierId: string;data: BodyType<DeleteBusDossierImageBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiDeleteBusDossierImage(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBusDossierImageMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBusDossierImage>>>
    export type ApiDeleteBusDossierImageMutationBody = BodyType<DeleteBusDossierImageBodyDto>
    export type ApiDeleteBusDossierImageMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBusDossierImage = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierImage>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBusDossierImage>>,
        TError,
        {busDossierId: string;data: BodyType<DeleteBusDossierImageBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiDeleteBusDossierImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUploadBusDossierVideo = (
    busDossierId: MaybeRef<string | undefined | null>,
    uploadBusDossierVideoBodyDto: MaybeRef<UploadBusDossierVideoBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
uploadBusDossierVideoBodyDto = unref(uploadBusDossierVideoBodyDto);
      const formData = new FormData();
formData.append('busDossierVideo', uploadBusDossierVideoBodyDto.busDossierVideo)

      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/videos`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getApiUploadBusDossierVideoMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierVideo>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierVideoBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierVideo>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierVideoBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUploadBusDossierVideo>>, {busDossierId: string;data: BodyType<UploadBusDossierVideoBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUploadBusDossierVideo(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUploadBusDossierVideoMutationResult = NonNullable<Awaited<ReturnType<typeof apiUploadBusDossierVideo>>>
    export type ApiUploadBusDossierVideoMutationBody = BodyType<UploadBusDossierVideoBodyDto>
    export type ApiUploadBusDossierVideoMutationError = ErrorType<BackendErrorEntity>

    export const useApiUploadBusDossierVideo = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierVideo>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierVideoBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUploadBusDossierVideo>>,
        TError,
        {busDossierId: string;data: BodyType<UploadBusDossierVideoBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUploadBusDossierVideoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBusDossierVideo = (
    busDossierId: MaybeRef<string | undefined | null>,
    deleteBusDossierVideoBodyDto: MaybeRef<DeleteBusDossierVideoBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
deleteBusDossierVideoBodyDto = unref(deleteBusDossierVideoBodyDto);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/videos`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteBusDossierVideoBodyDto
    },
      options);
    }
  


export const getApiDeleteBusDossierVideoMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierVideo>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierVideoBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierVideo>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierVideoBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBusDossierVideo>>, {busDossierId: string;data: BodyType<DeleteBusDossierVideoBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiDeleteBusDossierVideo(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBusDossierVideoMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBusDossierVideo>>>
    export type ApiDeleteBusDossierVideoMutationBody = BodyType<DeleteBusDossierVideoBodyDto>
    export type ApiDeleteBusDossierVideoMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBusDossierVideo = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierVideo>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierVideoBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBusDossierVideo>>,
        TError,
        {busDossierId: string;data: BodyType<DeleteBusDossierVideoBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiDeleteBusDossierVideoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUploadBusDossierDamageImage = (
    busDossierId: MaybeRef<string | undefined | null>,
    uploadBusDossierDamageImageBodyDto: MaybeRef<UploadBusDossierDamageImageBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
uploadBusDossierDamageImageBodyDto = unref(uploadBusDossierDamageImageBodyDto);
      const formData = new FormData();
formData.append('busDossierDamageImage', uploadBusDossierDamageImageBodyDto.busDossierDamageImage)

      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/damageImages`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getApiUploadBusDossierDamageImageMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierDamageImage>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierDamageImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierDamageImage>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierDamageImageBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUploadBusDossierDamageImage>>, {busDossierId: string;data: BodyType<UploadBusDossierDamageImageBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUploadBusDossierDamageImage(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUploadBusDossierDamageImageMutationResult = NonNullable<Awaited<ReturnType<typeof apiUploadBusDossierDamageImage>>>
    export type ApiUploadBusDossierDamageImageMutationBody = BodyType<UploadBusDossierDamageImageBodyDto>
    export type ApiUploadBusDossierDamageImageMutationError = ErrorType<BackendErrorEntity>

    export const useApiUploadBusDossierDamageImage = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierDamageImage>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierDamageImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUploadBusDossierDamageImage>>,
        TError,
        {busDossierId: string;data: BodyType<UploadBusDossierDamageImageBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUploadBusDossierDamageImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBusDossierDamageImage = (
    busDossierId: MaybeRef<string | undefined | null>,
    deleteBusDossierDamageImageBodyDto: MaybeRef<DeleteBusDossierDamageImageBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
deleteBusDossierDamageImageBodyDto = unref(deleteBusDossierDamageImageBodyDto);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/damageImages`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteBusDossierDamageImageBodyDto
    },
      options);
    }
  


export const getApiDeleteBusDossierDamageImageMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierDamageImage>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierDamageImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierDamageImage>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierDamageImageBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBusDossierDamageImage>>, {busDossierId: string;data: BodyType<DeleteBusDossierDamageImageBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiDeleteBusDossierDamageImage(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBusDossierDamageImageMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBusDossierDamageImage>>>
    export type ApiDeleteBusDossierDamageImageMutationBody = BodyType<DeleteBusDossierDamageImageBodyDto>
    export type ApiDeleteBusDossierDamageImageMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBusDossierDamageImage = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierDamageImage>>, TError,{busDossierId: string;data: BodyType<DeleteBusDossierDamageImageBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBusDossierDamageImage>>,
        TError,
        {busDossierId: string;data: BodyType<DeleteBusDossierDamageImageBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiDeleteBusDossierDamageImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUploadBusDossierRegistrationDocument = (
    busDossierId: MaybeRef<string | undefined | null>,
    uploadBusDossierRegistrationDocumentBodyDto: MaybeRef<UploadBusDossierRegistrationDocumentBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
uploadBusDossierRegistrationDocumentBodyDto = unref(uploadBusDossierRegistrationDocumentBodyDto);
      const formData = new FormData();
formData.append('busDossierRegistrationDocument', uploadBusDossierRegistrationDocumentBodyDto.busDossierRegistrationDocument)

      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/registrationDocument`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getApiUploadBusDossierRegistrationDocumentMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierRegistrationDocument>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierRegistrationDocumentBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierRegistrationDocument>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierRegistrationDocumentBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUploadBusDossierRegistrationDocument>>, {busDossierId: string;data: BodyType<UploadBusDossierRegistrationDocumentBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUploadBusDossierRegistrationDocument(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUploadBusDossierRegistrationDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof apiUploadBusDossierRegistrationDocument>>>
    export type ApiUploadBusDossierRegistrationDocumentMutationBody = BodyType<UploadBusDossierRegistrationDocumentBodyDto>
    export type ApiUploadBusDossierRegistrationDocumentMutationError = ErrorType<BackendErrorEntity>

    export const useApiUploadBusDossierRegistrationDocument = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierRegistrationDocument>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierRegistrationDocumentBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUploadBusDossierRegistrationDocument>>,
        TError,
        {busDossierId: string;data: BodyType<UploadBusDossierRegistrationDocumentBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUploadBusDossierRegistrationDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBusDossierRegistrationDocument = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/registrationDocument`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteBusDossierRegistrationDocumentMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierRegistrationDocument>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierRegistrationDocument>>, TError,{busDossierId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBusDossierRegistrationDocument>>, {busDossierId: string}> = (props) => {
          const {busDossierId} = props ?? {};

          return  apiDeleteBusDossierRegistrationDocument(busDossierId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBusDossierRegistrationDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBusDossierRegistrationDocument>>>
    
    export type ApiDeleteBusDossierRegistrationDocumentMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBusDossierRegistrationDocument = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierRegistrationDocument>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBusDossierRegistrationDocument>>,
        TError,
        {busDossierId: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteBusDossierRegistrationDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUploadBusDossierMaintenanceCard = (
    busDossierId: MaybeRef<string | undefined | null>,
    uploadBusDossierMaintenanceCardBodyDto: MaybeRef<UploadBusDossierMaintenanceCardBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
uploadBusDossierMaintenanceCardBodyDto = unref(uploadBusDossierMaintenanceCardBodyDto);
      const formData = new FormData();
formData.append('busDossierMaintenanceCard', uploadBusDossierMaintenanceCardBodyDto.busDossierMaintenanceCard)

      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/maintenanceCard`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getApiUploadBusDossierMaintenanceCardMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierMaintenanceCard>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierMaintenanceCardBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierMaintenanceCard>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierMaintenanceCardBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUploadBusDossierMaintenanceCard>>, {busDossierId: string;data: BodyType<UploadBusDossierMaintenanceCardBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUploadBusDossierMaintenanceCard(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUploadBusDossierMaintenanceCardMutationResult = NonNullable<Awaited<ReturnType<typeof apiUploadBusDossierMaintenanceCard>>>
    export type ApiUploadBusDossierMaintenanceCardMutationBody = BodyType<UploadBusDossierMaintenanceCardBodyDto>
    export type ApiUploadBusDossierMaintenanceCardMutationError = ErrorType<BackendErrorEntity>

    export const useApiUploadBusDossierMaintenanceCard = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierMaintenanceCard>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierMaintenanceCardBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUploadBusDossierMaintenanceCard>>,
        TError,
        {busDossierId: string;data: BodyType<UploadBusDossierMaintenanceCardBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUploadBusDossierMaintenanceCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBusDossierMaintenanceCard = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/maintenanceCard`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteBusDossierMaintenanceCardMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierMaintenanceCard>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierMaintenanceCard>>, TError,{busDossierId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBusDossierMaintenanceCard>>, {busDossierId: string}> = (props) => {
          const {busDossierId} = props ?? {};

          return  apiDeleteBusDossierMaintenanceCard(busDossierId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBusDossierMaintenanceCardMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBusDossierMaintenanceCard>>>
    
    export type ApiDeleteBusDossierMaintenanceCardMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBusDossierMaintenanceCard = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierMaintenanceCard>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBusDossierMaintenanceCard>>,
        TError,
        {busDossierId: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteBusDossierMaintenanceCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUploadBusDossierCocDocument = (
    busDossierId: MaybeRef<string | undefined | null>,
    uploadBusDossierCocDocumentBodyDto: MaybeRef<UploadBusDossierCocDocumentBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
uploadBusDossierCocDocumentBodyDto = unref(uploadBusDossierCocDocumentBodyDto);
      const formData = new FormData();
formData.append('busDossierCocDocument', uploadBusDossierCocDocumentBodyDto.busDossierCocDocument)

      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/cocDocument`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getApiUploadBusDossierCocDocumentMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierCocDocument>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierCocDocumentBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierCocDocument>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierCocDocumentBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUploadBusDossierCocDocument>>, {busDossierId: string;data: BodyType<UploadBusDossierCocDocumentBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUploadBusDossierCocDocument(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUploadBusDossierCocDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof apiUploadBusDossierCocDocument>>>
    export type ApiUploadBusDossierCocDocumentMutationBody = BodyType<UploadBusDossierCocDocumentBodyDto>
    export type ApiUploadBusDossierCocDocumentMutationError = ErrorType<BackendErrorEntity>

    export const useApiUploadBusDossierCocDocument = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadBusDossierCocDocument>>, TError,{busDossierId: string;data: BodyType<UploadBusDossierCocDocumentBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUploadBusDossierCocDocument>>,
        TError,
        {busDossierId: string;data: BodyType<UploadBusDossierCocDocumentBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUploadBusDossierCocDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBusDossierCocDocument = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/cocDocument`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteBusDossierCocDocumentMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierCocDocument>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierCocDocument>>, TError,{busDossierId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBusDossierCocDocument>>, {busDossierId: string}> = (props) => {
          const {busDossierId} = props ?? {};

          return  apiDeleteBusDossierCocDocument(busDossierId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBusDossierCocDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBusDossierCocDocument>>>
    
    export type ApiDeleteBusDossierCocDocumentMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBusDossierCocDocument = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBusDossierCocDocument>>, TError,{busDossierId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBusDossierCocDocument>>,
        TError,
        {busDossierId: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteBusDossierCocDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUpdateOnSiteDataCollected = (
    busDossierId: MaybeRef<string | undefined | null>,
    updateOnSiteDataCollectedBodyDto: MaybeRef<UpdateOnSiteDataCollectedBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
updateOnSiteDataCollectedBodyDto = unref(updateOnSiteDataCollectedBodyDto);
      
      return fetchApiInstance<BusDossierEntity>(
      {url: `/busDossiers/${busDossierId}/onSiteDataCollected`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateOnSiteDataCollectedBodyDto
    },
      options);
    }
  


export const getApiUpdateOnSiteDataCollectedMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateOnSiteDataCollected>>, TError,{busDossierId: string;data: BodyType<UpdateOnSiteDataCollectedBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateOnSiteDataCollected>>, TError,{busDossierId: string;data: BodyType<UpdateOnSiteDataCollectedBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateOnSiteDataCollected>>, {busDossierId: string;data: BodyType<UpdateOnSiteDataCollectedBodyDto>}> = (props) => {
          const {busDossierId,data} = props ?? {};

          return  apiUpdateOnSiteDataCollected(busDossierId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateOnSiteDataCollectedMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateOnSiteDataCollected>>>
    export type ApiUpdateOnSiteDataCollectedMutationBody = BodyType<UpdateOnSiteDataCollectedBodyDto>
    export type ApiUpdateOnSiteDataCollectedMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateOnSiteDataCollected = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateOnSiteDataCollected>>, TError,{busDossierId: string;data: BodyType<UpdateOnSiteDataCollectedBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateOnSiteDataCollected>>,
        TError,
        {busDossierId: string;data: BodyType<UpdateOnSiteDataCollectedBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateOnSiteDataCollectedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDownloadBusDossierImages = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<void>(
      {url: `/busDossiers/${busDossierId}/images/download`, method: 'GET'
    },
      options);
    }
  

export const getApiDownloadBusDossierImagesQueryKey = (busDossierId: MaybeRef<string | undefined | null>,) => {
    return ['busDossiers',busDossierId,'images','download'] as const;
    }

    
export const getApiDownloadBusDossierImagesQueryOptions = <TData = Awaited<ReturnType<typeof apiDownloadBusDossierImages>>, TError = ErrorType<BackendErrorEntity>>(busDossierId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiDownloadBusDossierImages>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiDownloadBusDossierImagesQueryKey(busDossierId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiDownloadBusDossierImages>>> = () => apiDownloadBusDossierImages(busDossierId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(busDossierId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiDownloadBusDossierImages>>, TError, TData> 
}

export type ApiDownloadBusDossierImagesQueryResult = NonNullable<Awaited<ReturnType<typeof apiDownloadBusDossierImages>>>
export type ApiDownloadBusDossierImagesQueryError = ErrorType<BackendErrorEntity>



export function useApiDownloadBusDossierImages<TData = Awaited<ReturnType<typeof apiDownloadBusDossierImages>>, TError = ErrorType<BackendErrorEntity>>(
 busDossierId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiDownloadBusDossierImages>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiDownloadBusDossierImagesQueryOptions(busDossierId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiReprocessBusDossierImages = (
    busDossierId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      busDossierId = unref(busDossierId);
      
      return fetchApiInstance<void>(
      {url: `/busDossiers/${busDossierId}/images/reprocess`, method: 'GET'
    },
      options);
    }
  

export const getApiReprocessBusDossierImagesQueryKey = (busDossierId: MaybeRef<string | undefined | null>,) => {
    return ['busDossiers',busDossierId,'images','reprocess'] as const;
    }

    
export const getApiReprocessBusDossierImagesQueryOptions = <TData = Awaited<ReturnType<typeof apiReprocessBusDossierImages>>, TError = ErrorType<BackendErrorEntity>>(busDossierId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiReprocessBusDossierImages>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiReprocessBusDossierImagesQueryKey(busDossierId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiReprocessBusDossierImages>>> = () => apiReprocessBusDossierImages(busDossierId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(busDossierId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiReprocessBusDossierImages>>, TError, TData> 
}

export type ApiReprocessBusDossierImagesQueryResult = NonNullable<Awaited<ReturnType<typeof apiReprocessBusDossierImages>>>
export type ApiReprocessBusDossierImagesQueryError = ErrorType<BackendErrorEntity>



export function useApiReprocessBusDossierImages<TData = Awaited<ReturnType<typeof apiReprocessBusDossierImages>>, TError = ErrorType<BackendErrorEntity>>(
 busDossierId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiReprocessBusDossierImages>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiReprocessBusDossierImagesQueryOptions(busDossierId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



