import type { DeepPartial } from '#ui/types';

import { THEME_DEFAULT_PRIMARY_COLOR } from '~/constants/theme';

export default defineAppConfig({
    ui: {
        primary: THEME_DEFAULT_PRIMARY_COLOR,
        gray: 'cool',
        tooltip: {
            default: {
                openDelay: 500,
            } as DeepPartial<{ openDelay: number; closeDelay: number }, string>, // There is an error in type cheking probably related to the library type definition so it is necessary to force the type
        },
        formGroup: {
            error: 'form-group-error',
        },
        variables: {
            header: {
                height: '5rem',
            },
        },
    },
    // Fix build error "Property 'nuxtIcon' is missing in type 'AppConfig'" (https://github.com/nuxt/ui/issues/1045)
    nuxtIcon: {},
});
