<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';

import {
    apiGetBusFactoryAttributesList,
    getApiGetBusFactoryAttributesListQueryKey,
    useApiGetAdminUsers,
    useApiGetBrands,
    useApiGetCompanies,
    useApiGetModels,
    useApiGetVersions,
    useApiOfflineGetBusDossiers,
} from '~/apiClient';

// COMPOSABLES
const queryClient = useQueryClient();
const { data: session } = useAuthUtils();
const { paginationApiParams } = usePaginationParams();
useApiGetCompanies();
useApiGetAdminUsers();
useApiGetBrands();
useApiGetModels();
useApiGetVersions();

const { data: busDossiers } = useApiOfflineGetBusDossiers({ ...paginationApiParams.value, assigneeId: session.value?.id });

// DATA
const busDossierIds = computed<string[]>(() => busDossiers.value?.busDossiers.map(dossier => dossier.id) ?? []);

// WATCHERS
watch(
    busDossiers,
    val => {
        (val?.busDossiers ?? []).map(bd => {
            if (bd.busFactoryAttributes?.versionId) {
                const versionId = bd.busFactoryAttributes.versionId;
                apiGetBusFactoryAttributesList({ versionId }).then(bfaList => {
                    queryClient.setQueryData(getApiGetBusFactoryAttributesListQueryKey({ versionId }), () => ({
                        ...bfaList,
                    }));
                });
            }
        });
    },
    { once: true }
);
</script>
<template>
    <div class="invisible">
        <BusDossierOfflineIcons />
        <NuxtLink :to="{ name: 'bus-dossiers' }" />
        <NuxtLink :to="{ name: 'bus-dossiers-create' }" />
        <BusDossierOfflineLink v-for="id in busDossierIds" :id="id" :key="id" />
    </div>
</template>
